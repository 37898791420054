import { APICategory, Alert, getModelName } from "@merge-api/merge-javascript-shared";
import DocumentationSection from "components/docs/DocumentationSection";
import DocsHelmet from "components/docs/shared-components/DocsHelmet";
import StaticFancyPageContainer from "components/docs/shared-components/StaticFancyPageContainer";
import React from "react";
import CodeExample from "components/docs/code-examples/CodeExample";
import filePickerGif from "../../assets/gifs/filepicker.gif";
import styled from "styled-components";
import { FilePickerTabKey } from "types/types";
import { useState } from "react";
import { graphql } from "gatsby";
import CommonModelSection from "components/docs/sections/CommonModelSection";
import { getDefinitionForModelName } from "@merge-api/merge-javascript-shared";

const Table = styled.table`
  &&& {
    code {
      font-size: 13px;
    }
  }
`;

const Navbar = styled.div`
  display: flex;
  margin: 24px 0 24px 0;
  border-bottom: 1px solid var(--gray10);

  /* Retina */
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    border-bottom: 0.5px solid var(--gray20);
  }
`;

type NavbarItemProps = {
  isSelected: boolean;
};

const NavbarItem = styled.div<NavbarItemProps>`
  cursor: pointer;
  display: flex;
  border-bottom: ${({ isSelected }) => (isSelected ? "1px solid #075FF7" : "")}};
  

  & ~ & {
    margin: 0 0 0 26px;
  }
`;

// Get schemas for file storage objects
export const query = graphql`
  query FilePickerQuery {
    schemas(category: { eq: "filestorage" }) {
      schema
    }
  }
`;

const FilePickerPage = ({ data: { schemas } }) => {
  const title = "File Picker";
  const description =
    "Merge's File Picker is built into our embedded Merge Link component and provides a user-friendly interface for your customers to browse Files, Folders, and/or Drives in their connected File Storage account. File Picker does not impact your File Storage Linked Account syncs.";

  // state
  const [selectedTab, setSelectedTab] = useState<FilePickerTabKey>(FilePickerTabKey.FILE);

  const { schema } = schemas;
  const modelName = getModelName(selectedTab, APICategory.filestorage);
  const modelDefinition = getDefinitionForModelName(modelName, schema) ?? {};

  return (
    <StaticFancyPageContainer style={{ paddingBottom: "0" }}>
      <DocsHelmet title={title} description={description} />
      <div className="ml-12 mr-36">
        <h2 className="d-flex align-items-center text-[26px] leading-[36px]">File Picker </h2>
        <p className="mb-9">
          Merge's File Picker is built into our embedded Merge Link component and provides a
          user-friendly interface for your customers to browse Files, Folders, and/or Drives in
          their connected File Storage account. File Picker does not impact your File Storage Linked
          Account syncs.
        </p>
        <hr className="h-[1px] mt-0 mb-9"></hr>
        <div className="flex flex-row">
          <div className="w-1/2">
            <DocumentationSection title="Demo Video">
              <>Watch this quick video to learn the basics of how Merge's File Picker works.</>
            </DocumentationSection>
          </div>
          <div className="flex w-1/2 h-auto">
            <img className="max-w-full rounded" src={filePickerGif} alt="...Loading"></img>
          </div>
        </div>
        <hr className="my-9" />
        <DocumentationSection title="Setting up Merge Link with File Picker">
          <div className="flex flex-row items-center">
            <p className="w-1/2">
              To set up File Picker, please follow our{" "}
              <a href="https://docs.merge.dev/get-started/link/" target="_blank" rel="noreferrer">
                Embedded Link guide
              </a>{" "}
              to add our drop-in Merge Link component to our app.
            </p>
            <Alert showWarningIcon className="w-1/2">
              <>
                Make sure you have the latest version of{" "}
                <a
                  href="https://github.com/merge-api/react-merge-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  react-merge-link
                </a>
                .
              </>
            </Alert>
          </div>
          <p>
            Enabling the File Picker only requires a few additions to <b>step 2.</b>
          </p>
          <div className="flex flex-row">
            <div className="flex flex-col w-1/2 mr-10">
              <p className="mt-9">
                In addition to following step 2 of our Embedded Link guide, you will need to add a
                new parameter <code>filePickerConfig</code>.
              </p>
              <p>
                <code>onSubmit</code> is the only required input to <code>filePickerConfig</code>,
                and returns an array of selected objects from your user's workspace.
              </p>
              <p>
                This input is a callback and is left as <code>console.log()</code> in this example
                for you to decide how to handle Files, Folders, and Drives that your users have
                selected in the File Picker. If you are testing the File Picker in your dashboard,
                no callback is provided and the File Picker will automatically close on submit.
              </p>
              <p>
                In this example, the File Picker configuration allows multi-select of Files and
                Folders.
              </p>
              <p>
                See details about each <code>filePickerConfig</code> input below.
              </p>
            </div>
            <div className="w-1/2">
              <CodeExample
                folder="filestorage/file-picker"
                codeBlockName="Frontend - Initial Link with File Picker"
              />
            </div>
          </div>
        </DocumentationSection>
        <hr className="h-[1px] mt-9 mb-9"></hr>
        <DocumentationSection title="Configuration Inputs">
          <div className="overflow-auto mt-6 mb-9">
            <Table>
              <thead>
                <tr>
                  <td style={{ width: "20%" }}>Input</td>
                  <td style={{ width: "15%" }}>Type</td>
                  <td style={{ width: "65%" }}>Description</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <code>types</code>
                  </td>
                  <td>Array</td>
                  <td>
                    <p>
                      Optional array of object types that determines which types of objects your
                      users can pick.
                    </p>
                    <p>
                      Possible values for object types include: <code>FILE</code>,{" "}
                      <code>FOLDER</code>, <code>DRIVE</code>.
                    </p>
                    <p>
                      If <code>types</code> is empty, your users will be able to pick objects of any
                      type. If <code>types</code> is not empty, your users are restricted to
                      selecting from the object types in the array.
                    </p>
                    <p>
                      For example, if <code>types = ['FILE', 'FOLDER']</code>, your users will be
                      able to select Files and Folders, but not Drives.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <code>allowMultiSelect</code>
                  </td>
                  <td>Boolean</td>
                  <td>
                    <p>
                      Optional boolean field that allows your users to select multiple objects in
                      one File Picker session.
                    </p>
                    <p>
                      The default value is <code>false</code>, which restricts your users to only
                      select one object.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <code>onSubmit</code>
                  </td>
                  <td>Function</td>
                  <td>
                    <p>
                      Callback that returns an array of <code>MergeFileStorageData</code> objects as
                      its output.
                    </p>
                    <p>
                      See an example for <code>MergeFileStorageData</code> below.
                    </p>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </DocumentationSection>
        <hr className="h-[1px] mt-3 mb-9"></hr>
        <DocumentationSection title="Merge File Storage Data">
          <p>
            <code>MergeFileStorageData</code> properties should match our File Storage Common Model
            properties for the given workspace object type.
          </p>
          <p>Select the object type to see its corresponding properties and example response.</p>
          <Navbar>
            <NavbarItem
              key={"FILE"}
              onClick={() => setSelectedTab(FilePickerTabKey.FILE)}
              isSelected={selectedTab === FilePickerTabKey.FILE}
            >
              <p className="font-semibold">FILE</p>
            </NavbarItem>
            <NavbarItem
              key={"FOLDER"}
              onClick={() => setSelectedTab(FilePickerTabKey.FOLDER)}
              isSelected={selectedTab === FilePickerTabKey.FOLDER}
            >
              <p className="font-semibold">FOLDER</p>
            </NavbarItem>
            <NavbarItem
              key={"DRIVE"}
              onClick={() => setSelectedTab(FilePickerTabKey.DRIVE)}
              isSelected={selectedTab === FilePickerTabKey.DRIVE}
            >
              <p className="font-semibold">DRIVE</p>
            </NavbarItem>
          </Navbar>
          <div className="ml-3">
            <CommonModelSection
              category={APICategory.filestorage}
              document={schema}
              tag={""}
              modelDefinition={modelDefinition as any}
            />
          </div>
        </DocumentationSection>
      </div>
    </StaticFancyPageContainer>
  );
};

export default FilePickerPage;
